import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../layout";
import { ChevronRightFill } from "../icons";
import IconBack from "../../images/icon_back.png";

const Download = ({ lang, path }) => (
    <Layout lang={lang} path={path} i18nSEO="DataDownload">
        <View lang={lang} />
    </Layout>
);
Download.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Download;

const View = ({ lang }) => {
    const { t } = useTranslation();
    return (
        <div className="my6">
            <div className="header ml4" style={{ border: "none" }}>
                <div className="title">{t("DataDownload")}</div>
            </div>
            <div>
                <Link
                    className="link flexrow-center mb4"
                    to={`/${lang}/download/devices`}
                    aria-label={t("DataDownloadByDevice")}
                >
                    <div className="rightArrow">
                        <ChevronRightFill />
                    </div>
                    {t("ByDevice")}
                </Link>
            </div>
            <div>
                <Link
                    className="link flexrow-center mb4"
                    to={`/${lang}/download/parameter`}
                    aria-label={t("DataDownloadByParam")}
                >
                    <div className="rightArrow">
                        <ChevronRightFill />
                    </div>
                    {t("ByParam")}
                </Link>
            </div>
            <div>
                <Link
                    className="link flexrow-center mb6"
                    to={`/${lang}/download/year`}
                    aria-label={t("DataDownloadByYear")}
                >
                    <div className="rightArrow">
                        <ChevronRightFill />
                    </div>
                    {t("ByYear")}
                </Link>
            </div>
            <div className="flexrow ml4">
                <Link
                    className="btnIcon"
                    to={`/${lang}`}
                    aria-label={t("Back")}
                >
                    <img src={IconBack} alt="" />
                    <div>{t("Back")}</div>
                </Link>
            </div>
        </div>
    );
};
